import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import Sidebar from '../../../../Layout/Sidebar';
import { uploadImage, createOrUpdateMovieApi, getAllMovieLanguageApi, createOrUpdateMovieLanguageApi, getMovieByIdApi } from '../../../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../../../utils/BaseUrl';
import Spinwheel from '../../../common/Spinwheel';
import JoditEditor from 'jodit-react';
import { movieInitialValues, movieValidationSchema, } from '../../../common/Validation';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import Header from '../../../common/Header';
import TimePicker from 'react-time-picker';
import TimeField from 'react-simple-timefield';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import AsyncCreatableSelect from 'react-select/async-creatable';

function Movie() {
  const [fileTypeError, setFileTypeError] = useState({ id: null, message: "", error: 0 })
  const navigate = useNavigate()
  const param = useParams()
  const [data, setData] = useState(movieInitialValues)
  const [addSpin, setAddSpin] = useState(false)
  const [videoUpload, setVideoUpload] = useState(false);
  const location = useLocation();
  const [reRendor, setReRender] = useState(false);
  let path = location.pathname
  path = path.split("/").slice(-1)[0];
  path = (path[0].toUpperCase() + path.slice(1))
  const editor = useRef(null);
  const config = useMemo(() => {
    return {
      disablePlugins: ['Find', "Preview", "fullsize", "Change", "About", 'Print', "image", "file", "spellcheck", "selectall", "copyformat", "table", "hr", "symbols", "lineHeight", "classSpan", "paragraph"],
      removeButtons: ['undo', 'redo', 'Print', 'find', "video", "cut", "copy", "paste", "image", "source", "symbols", "lineHeight", "selectall", "paragraph", "superscript", "subscript"],
      placeholder: 'Start writing...',
      askBeforePasteFromWord: true,
      askBeforePasteHTML: false,
      enter: "br",
      hidePoweredByJodit: true,
      STATUSES: {
        beforeDestruct: "beforeDestruct",
        beforeInit: "beforeInit",
        destructed: "destructed",
        ready: "ready",
      }
    }
  }, []
  );



  const handleSubmit = async (values) => {
    try {
      let res
      if (param.id) {
        setAddSpin(true)
        res = await createOrUpdateMovieApi({ ...values, _id: param?.id, })
      } else {
        setAddSpin(true)
        res = await createOrUpdateMovieApi(values);
      }
      if (res?.status === 200) {
        toast.success(res.data?.message)
        setAddSpin(false)
        navigate("/ticket/movies", { replace: true })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAddSpin(false)
    }
  }

  const handleUpload = async (e, id, field) => {
    try {
      const file = e.target.files[0];
      const types = ['image/jpeg', 'image/png', 'image/gif']
      if (file) {
        if (types.includes(file.type)) {
          setFileTypeError({ id: id, message: "", error: 0 });
          const formData = new FormData();
          formData.append("file", file);
          formData.append("type", 3)
          formData.append("folder", "movie")
          const res = await uploadImage('/users/fileUpload', formData)
          formik.setFieldTouched(field, true)
          if (res.status === 200 && res.data?.status === 1) {
            formik.setFieldValue(field, res.data?.data?.fileName)
            toast.success("File uploaded");
          }
        } else {
          formik.setFieldTouched(field, true)
          formik.setFieldValue(field, "")
          setFileTypeError({ id: id, message: "Must be image only", error: 1 });
        }
      }

    } catch (error) {
      formik.setFieldTouched(field, true)
      console.log(error)
      if (error?.response === undefined) {
        toast.error("File size is too large")
      }
    }
  }

  console.log("data", data);

  const formik = useFormik({
    initialValues: param?.id ? data : movieInitialValues,
    onSubmit: handleSubmit,
    validationSchema: movieValidationSchema,
  });

  const removeCast = (e, index) => {
    e.preventDefault()
    formik.setFieldValue(`casts`, formik.values?.casts?.filter((ele, i) => {
      if (i === index) return false;
      return true;
    }))
  }

  const addNewCast = (e, index,) => {
    e.preventDefault();
    formik.setFieldValue(`casts`, [...formik.values?.casts, {
      name: "",
      img: "",
      role: ""
    }])
  }


  const languageOptions = async () => {
    try {
      const res = await getAllMovieLanguageApi()
      console.log(res?.data?.data);
      return res.data?.data?.map(lang => { return { label: lang?.name, value: lang?.name } })
    } catch (error) {
      console.log(error);
      return []
    }
  }

  console.log(formik.errors);

  const handleSelect = async (langs) => {
    console.log(langs, "e list");
    try {
      const newLang = langs?.filter(lang => lang?.__isNew__)

      if (newLang?.[0]?.__isNew__) {
        const res = await createOrUpdateMovieLanguageApi({ name: newLang?.[0]?.value })
      }
      const list = langs?.map(e => e?.value)
      formik.setFieldValue("languages", list)
    } catch (error) {

    }
  }

  const getMovieById = async () => {
    try {
      const res = await getMovieByIdApi(param?.id)
      console.log("movie", res?.data?.data?.[0]);
      setData(res?.data?.data?.[0] || movieInitialValues)
      formik.setValues(res?.data?.data?.[0] || movieInitialValues)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMovieById()
  }, [param?.id])

  console.log("languages", formik.values.languages);

  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title={<>Movie</>} />
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/movie/tickets"} className="breadcrumb-item active" aria-current="page">
                  Movie ticket
                </Link>
                <li className="breadcrumb-item">
                  movie
                </li>
              </ol>
            </nav>
            <div>
              <h2 className="report">Movie details</h2>
            </div>
            <hr />

            <div className="col-12" style={{ zIndex: "1" }}>
              <form action="" onSubmit={formik.handleSubmit} >
                <div className="row row-cols-lg-3 row-cols-md-2">
                  <div>
                    <label htmlFor="name" className="form-label">Name</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="name" placeholder="Enter movie name" name="name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z" />
                      </svg>
                      {formik.touched.name && formik.errors.name ? (<div className='req-error'>{formik.errors.name}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="title" className="form-label">Title</label>
                    <div className="position-relative">
                      <input className="form-control" type="text" id="title" placeholder="Enter title" name="title" value={formik.values.title} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill input-icon" viewBox="0 0 16 16">
                        <path d="M0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5A1.5 1.5 0 0 0 0 1.5z" />
                        <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177V10.5z" />
                      </svg>
                      {formik.touched.title && formik.errors.title ? (<div className='req-error'>{formik.errors.title}</div>) : null}
                    </div>
                    <br />
                  </div>

                  <div>
                    <label htmlFor="publishDate" className="form-label">Duration</label>
                    <div className="position-relative">
                      <TimeField
                        //  onChange={(e)=>console.log("durations",e?.target.value)}
                        onChange={(e) => formik.setFieldValue("duration", e?.target?.value)}
                        value={formik.values.duration}
                        showSeconds
                        className='form-control w-100' />

                      {formik.touched.publishDate && formik.errors.publishDate ? (<div className='req-error'>{formik.errors.publishDate}</div>) : null}
                    </div>
                    <br />
                  </div>
                 <div>
                    <label htmlFor="publishDatdse" className="form-label">Language</label>
                    <div>
                      <AsyncCreatableSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        onChange={handleSelect}
                        value={formik.values.languages?.map(lang => { return { label: lang, value: lang } })}
                        loadOptions={languageOptions}
                      />
                    </div>
                 </div>

                  <div>
                    <label htmlFor="coverImg" className="form-label">Cover image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.coverImg ? ImageUrl + formik.values.coverImg : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="coverImg" className="form-control" name="coverImg" accept="image/*" onChange={(e) => handleUpload(e, "coverImg", "coverImg")} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.coverImg && (formik.errors.coverImg || <div className='req-error'>{fileTypeError?.id == "coverImg" && fileTypeError?.error != 0 && fileTypeError?.error}</div>)}
                    <br />
                  </div>
                  <div>
                    <label htmlFor="img" className="form-label">Image</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {<img src={formik.values.img ? ImageUrl + formik.values.img : process.env.PUBLIC_URL + "/images/image.svg"} width="70px" height="50px" style={{ objectFit: "contain" }} className="rounnded" alt="Selected file" />}
                      </div>
                      <div className="btn_upload">
                        <input type="file" id="img" className="form-control" name="img" accept="image/*" onChange={(e) => handleUpload(e, "img", "img")} />
                        Upload
                      </div>
                    </div>
                    {formik.touched.img && (formik.errors.img || <div className='req-error'>{fileTypeError?.id == "img" && fileTypeError?.error != 0 && fileTypeError?.message}</div>)}
                    <br />
                  </div>
                </div>
                <div className='my-3'>
                  <h3>Casts</h3>
                  {formik.values?.casts.map((ele2, index2) => {
                    return (
                      <div className='row mt-3' key={"slkjdfow4hkn" + index2}>
                        {console.log(formik.values.casts, formik.values?.casts[index2]?.name, index2)}
                        <div className="col-lg-3">
                          <label htmlFor={"name" + index2} className="form-label">Name</label>
                          <div className="position-relative">
                            <input className="form-control" type="text" id={"name" + index2} placeholder="Enter cast name" name="name" value={formik.values?.casts[index2]?.name} onChange={(e) => { formik.setFieldValue(`casts[${index2}].name`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`casts[${index2}].name`, true) }} />
                            {formik.touched?.casts?.[index2]?.name && formik.errors?.casts?.[index2]?.name ? (<div className='req-error'>{formik.errors?.casts?.[index2]?.name}</div>) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label htmlFor={"role" + index2} className="form-label">Role</label>
                          <div className="position-relative">
                            <input className="form-control" type="text" id={"role" + index2} placeholder="Enter cast role" name="role" value={formik.values?.casts[index2]?.role} onChange={(e) => { formik.setFieldValue(`casts[${index2}].role`, e.target.value) }} onBlur={() => { formik.setFieldTouched(`casts[${index2}].role`, true) }} />
                            {formik.touched?.casts?.[index2]?.role && formik.errors?.casts?.[index2]?.role ? (<div className='req-error'>{formik.errors?.casts?.[index2]?.role}</div>) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className='d-flex'><label htmlFor="profile" className="form-label">Image </label></div>
                          <div>
                            <div id={`cast-img-${index2}`} className="d-flex justify-content-between align-items-center">
                              <div className="position-relative">

                                {<img src={formik.values?.casts?.[index2]?.img ? ImageUrl + formik.values?.casts?.[index2]?.img : process.env.PUBLIC_URL + "/images/image.svg"} width="50px" height="50px" className="rounded" alt="Selected file" />}
                                {
                                  <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera pro-camera" viewBox="0 0 16 16">
                                      <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                      <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                    </svg>
                                  </>
                                }
                              </div>
                              <div className="btn_upload">
                                <input type="file" id="upload_file" accept="image/*" className="form-control" name="profileImage" onChange={(e) => { handleUpload(e, `cast-img-${index2}`, `casts[${index2}].img`) }} />
                                Upload
                              </div>


                            </div>
                            {formik.touched?.casts?.[index2]?.img && formik.errors?.casts?.[index2]?.img ? (<div className='req-error'>{formik.errors?.casts?.[index2]?.img}</div>) : null}
                          </div>

                        </div>
                        <div className="col-2 d-flex justify-content-center align-items-center">

                          <div className=" text-end mb-3">
                            {(formik.values?.casts?.length === (index2 + 1) && formik.values?.casts?.length < 5) && <button type="button" className="me-3 plusButton" onClick={(e) => { addNewCast(e, index2) }}><span>+</span></button>}
                            {formik.values?.casts?.length !== 1 && <button type="button" className=" plusButton" onClick={(e) => { removeCast(e, index2) }}><span>-</span></button>}
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>


                <label htmlFor="description" className="form-label">Description</label>
                <div className="position-relative">
                  <JoditEditor
                    ref={editor}
                    config={config}
                    value={formik.values.description}
                    id='description'
                    onBlur={(e) => {
                      formik.setFieldTouched("description", true);
                      e === "<p><br></p>" ? formik.setFieldValue("description", "") : formik.setFieldValue("description", e);
                    }}
                    onChange={(e) => { }}
                  />
                  {formik.touched.description && formik.errors.description ? (<div className='req-error'>{formik.errors.description}</div>) : null}
                </div>
                <br />
                <div className="text-center d-flex justify-content-around">
                  <button type="submit" className="btn-custom w-auto mt-2" disabled={addSpin} >{addSpin ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <div className="btn-custom w-auto mt-2" onClick={() => { window.history.back(); }}  >Cancel</div>
                </div>
              </form>
              <br />
            </div>
            <div className="col-lg-8"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Movie