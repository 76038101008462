import * as Yup from 'yup'
// admin sign in
export const SignInInitaialValue = {
  email: "",
  password: "",
  checkbox: false
}

export const SignInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  // checkbox:Yup.bool().oneOf([true], 'must be checked').required()
})

// forget password

export const forgetPasswordIntial = {
  email: "",
  otp: undefined
}

export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  otp: Yup.number("Otp should be number").required("Otp is required"),
})

// otp validation 

export const optIntialValue = {
  email: ""
}

export const otpValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
})

export const inviteVendorInitialValue = {
  email: "",
  role: "shopVendor",
}

export const inviteVendorValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
})

// update admin profile

export const adminUserIntialValues = {
  email: "",
  password: "",
  name: "",
  profileImage: "",
  role: "admin"
}

export const adminUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  name: Yup.string()
    .required('Name is required'),
  profileImage: Yup.string(),
  role: Yup.string().required("Role is required"),
  // .required('Profile image URL is required'),
});

export const vendorUserIntialValues = {
  email: "",
  name: "",
  profileImage: "",
  role: "vendor",
  password: "",
  _id: ""
}

export const VendorUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  name: Yup.string()
    .required('Name is required'),
  profileImage: Yup.string(),
  role: Yup.string(),
  password: Yup.string().required('Password is required').when('_id', ([_id], schema) => {

    if (_id) {
      return Yup.string()
    }
    return schema;
  },
  ),


});

export const updateAdminIntialValue = {
  profileImage: "",
  name: "",
  // role: "",
  email: "",
  phoneNumber: undefined
}

export const updateAdminProfileSchema = Yup.object({
  profileImage: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name must not exceed 25 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  // role: Yup.string().required('Required'),
  email: Yup.string().email().required('Email is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')
})

// Essential number validation

export const AddEssentialInitialValue = {
  logo: "",
  name: "",
  phone: [""]
}

export const essentialValidation =
  Yup.object().shape({
    logo: Yup.string(),
    name: Yup.string().required('Name is required'),
    phone: Yup.array().of(Yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')).min(1, 'At least one phone number is required'),
  });



// user Edit Validation

export const userEditIntialValue = {
  // work_zipcode: "",
  // work_country: "",
  // work_Address: "",
  name: "",
  email: "",
  phoneNumber: "",
  dob: "",
  compensation: "",
  country: "",
  experience: "",
  organization: "",
  jobTitle: "",
  qualification: "",
  correspondence_Address: "",
  correspondence_country: "",
  correspondence_zipcode: "",
  // role: "",
  birthPlace: "",
  zipCode: "",
  gender: "",
}




export const userEditValidation = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  email: Yup.string().trim().email('Invalid email address').required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  dob: Yup.date().required('Date of birth is required'),
  compensation: Yup.number().typeError('Compensation must be a number').required('Compensation is required'),
  country: Yup.string().trim().required('Country is required'),
  experience: Yup.string().trim().required('Experience is required'),
  organization: Yup.string().trim().required('Organization is required'),
  jobTitle: Yup.string().trim().required('Job title is required'),
  qualification: Yup.string().trim().required('Qualification is required'),
  correspondence_Address: Yup.string().trim().required('Home address is required'),
  correspondence_country: Yup.string().trim().required('Home country is required'),
  correspondence_zipcode: Yup.string().trim().required('Home zipcode is required'),
  // role: Yup.string().trim().required('Role is required'),
  birthPlace: Yup.string().trim().required('Birth place is required'),
  zipCode: Yup.string().trim().required('Zip code is required'),
  gender: Yup.string().trim().required('Gender is required'),
  // work_zipcode: Yup.string().trim().required('Work zipcode is required'),
  // work_country: Yup.string().trim().required('Work country is required'),
  // work_Address: Yup.string().trim().required('Work address is required'),
});

// reset Password validation

export const resetpasswordInitial = {
  password: "",
  confirmPassword: ""
}

export const resetPasswordValidation = Yup.object({
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

// add Embasseies validation

export const addEmbassiesIntialValues = {
  imageLogo: "",
  myDocumentId: "",
  name: "",
  address: ""
}

export const addEmbassiesValidation = Yup.object({
  // image: Yup.string().required("Required"),
  myDocumentId: Yup.string().required("Categore is required"),
  name: Yup.string().required("Office Name is required"),
  address: Yup.string().required("Address is required")
})


// add map validation

export const mapIntialValue = {
  image: "",
  name: "",
  address: "",
  isPinned: false,
  status: "",
  address2: "",
  description: "",
  phoneNumber: "",
}

export const mapValidationSchema = Yup.object({
  image: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(100, 'Name must not exceed 100 characters'),
  // .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  address: Yup.string().required("Location is required"),
  address2: Yup.string().required("Address is required"),
  description: Yup.string().required("Descrition is required"),
  isPinned: Yup.boolean(),
  status: Yup.string().required("Map type is required"),
})

// jobs post validation 


export const jobsPostInitialValues = {
  title: "",
  companyId: "",
  workExp: "",
  description: "",
  employmentType: "",
  skillsRequired: [],
  noOfPosition: "",
  salaryRangeFrom: "",
  salaryRangeTo: "",
  rangeSelector: false,
  location: "",
}

export const jobsPostValidationSchema = Yup.object({
  title: Yup.string().required('Title is required').max(60, 'Title must not exceed 60 characters'),
  companyId: Yup.mixed().required('Company is required'),
  workExp: Yup.string().required('Work experience is required'),
  employmentType: Yup.string().required('Employment type is required'),
  skillsRequired: Yup.array().min(1, 'At least one skill is required'),
  description: Yup.string().required('Description is required'),
  noOfPosition: Yup.mixed().test('noOfPosition', 'Required Employee must be a number between 1 to 1000', value => {
    if (!value) return false;
    return !isNaN(value) && value >= 1 && value <= 1000;
  }).required('Required Employee is required'),
  salaryRangeFrom: Yup.number().required('Salary range from is required').min(0, 'Salary range must be a positive number or zero').typeError('Salary range must be number'),
  salaryRangeTo: Yup.number().required('Salary range to is required').min(Yup.ref('salaryRangeFrom'), 'Salary range to must be greater').typeError('Salary range must be number'),
  location: Yup.string().required("Location is required"),
})

//add company validation

export const companyPostInitialValues = {
  logo: "",
  name: "",
  emailAddress: "",
  phoneNumber: "",
  url: "",
  description: "",
  locality: "",
  industry: "",
  address: ""
}

export const companyPostValidationSchema = Yup.object({
  logo: Yup.string().required("Logo is required"),
  name: Yup.string().required('Company name is required').max(60, 'Company name must not exceed 60 characters'),
  emailAddress: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number'),
  url: Yup.string().url('Invalid URL').required('URL is required'),
  description: Yup.string().required('Description is required'),
  locality: Yup.string().required('Location is required'),
  industry: Yup.string().required('Industry is required'),
  address: Yup.string().required('Address is required'),
})

// rating validation

export const ratingIntialValues = {
  name: "",
  rating: "",
  companyId: "",
  review: "",
  jobId: ""
}

export const ratingValidatonSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  rating: Yup.mixed().test('valid-rating', 'Rating must be a number between 1 to 5', value => {
    if (!value) return false;
    const ratingNum = parseFloat(value);
    return !isNaN(ratingNum) && ratingNum >= 1 && ratingNum <= 5;
  }).required('Rating is required'),
  companyId: Yup.string().required('Company ID is required'),
  review: Yup.string().required('Review is required'),
  // jobId: Yup.string(),
});

// add meesage validation

export const addSosMessIntialValues = {
  name: ""
}

export const addSosMessValidationSchema = Yup.object().shape({
  name: Yup.string().required('Message is required'),
})

// news validation

export const newsUploadInitialValues = {
  newsTitle: "",
  newsHeading: "",
  publishDate: "",
  newsContent: '',
  publishBy: "",
  type: "article",
  thumbnail: "",
  isPublished: false,
  onPriority: false,
  isBanner: false,
  video: "",
  newsSource: "",
  videoTimeDuration: ""
}

export const newsUploadValidationSchema = Yup.object().shape({

  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('video is required')
    }
    return schema;
  },
  ),

  newsTitle: Yup.string().required('News title is required')
    .max(150, 'New title must not exceed 150 characters'),
  newsHeading: Yup.string().required('News heading is required').max(150, 'New heading must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required')
    .test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
      const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
      if (!dateRegex.test(value)) {
        return false;
      }
      const [month, day, year] = value.split('-').map(Number);
      const dateObject = new Date(year, month - 1, day);
      return (
        dateObject.getDate() === day &&
        dateObject.getMonth() === month - 1 &&
        dateObject.getFullYear() === year
      );
    })
  ,
  newsContent: Yup.string().required('News content is required'),
  newsSource: Yup.string().required('News content is required'),
  publishBy: Yup.string().required('Published by is required').max(100, 'Published by must not exceed 100 characters'),

});

export const movieInitialValues = {
  name: "",
  title: "",
  description: "",
  coverImg: "",
  img: "",
  duration: "00:00:00",
  languages: [],
  casts: [{ name: "", img: "", role: "" }],
  isBanner: 0
}

export const movieValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  coverImg: Yup.string().required('Cover image is required'),
  img: Yup.string().required('Image is required'),
  duration: Yup.string().required('Duration is required'),
  languages: Yup.array().min(1, "Must have 1 language").required('Languages is required'),
  casts: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    img: Yup.string().required('Image is required'),
    role: Yup.string().required('Role is required'),
  })).min(1, "Must have 1 cast").required('Casts is required'),


});



export const movieShowInitialValues = {
  movieCinemaId: {},
  timeDate: "",
  noOfSeats: "",
  price: "",
  tax: "",
  language: "",
}

export const movieShowValidationSchema = Yup.object().shape({
  movieCinemaId: Yup.object().required('Please select cinema').test('select-cinema', 'Please select cinema', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  timeDate: Yup.string().required('Movie show timing is required'),
  noOfSeats: Yup.number().typeError("No of seat must be number").required('No of seats is required').positive("must be a positive number"),
  price: Yup.number().required('Price is required').typeError("Price must be number").positive("must be a positive number"),
  tax: Yup.number().required('Tax is required').typeError("Tax must be number").positive("must be a positive number"),
  language: Yup.string().required('Language is required'),
});

export const busRouteInitialValue = {
  busOperatorId: {},
  departure: "",
  departurePlace: "",
  arrivalPlace: "",
  arrivalTime: "",
  totalSeat: "",
  price: "",
  busType: {},
  seatType: {},
  pickPoint: [{
    place: "",
    time: "",
  }],
  dropPoint: [{
    place: "",
    time: "",
  }]
}

export const busRouteValidationSchema = Yup.object().shape({
  busOperatorId: Yup.object().required('Please select operator').test('select-operator', 'Please select operator', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  departurePlace: Yup.string().required('Departure place is required'),
  departure: Yup.string().required('Departure time is required'),
  arrivalPlace: Yup.string().required('Arrival place is required'),
  arrivalTime: Yup.string().required('Arrival time is required'),
  totalSeat: Yup.number().typeError("Total seat must be number").required('Total seat is required'),
  price: Yup.number().typeError("Price must be number").required('Price is required'),
  busType: Yup.object().required('Please select bus type').test('select-bus-type', 'Please select bus type', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  seatType: Yup.object().required('Please select seat type').test('select-seat-type', 'Please select seat type', (value) => {
    if (value?.value) {
      return true;
    }
  }),
  pickPoint: Yup.array(Yup.object().shape({
    place: Yup.string().required('Place is required'),
    time: Yup.string().required('Time is required'),
  })).min(1, "Must have 1 pickup point").required('Pickup point is required'),
  dropPoint: Yup.array(Yup.object().shape({
    place: Yup.string().required('Place is required'),
    time: Yup.string().required('Time is required'),
  })).min(1, "Must have 1 drop point").required('Drop point is required'),
});

export const hotelInitialValue = {
  name: "",
  address: "",
  image: "",
  starRating: "",
  minimumPrice: "",
  propertyType: "",
  hotelCondition: "",
  isFreeCancellation: false,
  about: "",
  googleLocation: {
    address: "",
    lang: "",
    lat: ""
  },
  facilities: [{
    name: "",
    icon: "",
  }],
}


export const hotelValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  image: Yup.string().required('Image is required'),
  starRating: Yup.number().min(1, "Minimum 1 rating").max(7, "Maximum 7 rating").required('Star rating is required').typeError("Rating must be a number"),
  minimumPrice: Yup.number().min(0, "Must be postive number").required('Minimum price is required').typeError("Minimum price must be a number"),
  propertyType: Yup.string().required('Property type is required'),
  hotelCondition: Yup.string().required('Hotel condition is required'),
  about: Yup.string().required('About is required'),
  googleLocation: Yup.object().required('Please select google location').test('select-googleLocation', 'Please select google location', (value) => {
    if (value?.address && value?.lang && value?.lat) {
      return true;
    }
  }),
  facilities: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    icon: Yup.string().required('Icon is required'),
  })).min(1, "Must have 1 facilities").required('Facilities required'),
});

export const hotelRoomInitialValue = {
  name: "",
  price: "",
  mealPreference: "",
  bedPreference: "",
  guestNumber: "",
  images: [""],
  facilities: [{
    name: "",
    icon: "",
  }],
}


export const hotelRoomValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.number().min(0, "Must be postive number").required('Price is required').typeError("Price must be a number"),
  guestNumber: Yup.number().min(0, "Must be postive number").required('Guests is required').typeError("Guest must be a number"),
  mealPreference: Yup.string().required('Meal preference is required'),
  bedPreference: Yup.string().required('Bed preference is required'),
  images: Yup.array().min(1, "Must have 1 image").required('Images required'),
  facilities: Yup.array(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    icon: Yup.string().required('Icon is required'),
  })).min(1, "Must have 1 facilities").required('Facilities required'),
});


export const ticketStatusInitalValue = {
  status: "",
  ticketFile: "",
}

export const ticketStatusValidationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  ticketFile: Yup.string().required('Attachment is required'),
});

export const busOperatorInitialValue = {
  image: "",
  name: "",
  phone: "",
  address: "",

}

export const busOperatorValidationSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
  address: Yup.string().required('Address is required'),

});

export const movieCinemaInitalValue = {
  name: "",
  location: "",
}

export const movieCinemaValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  location: Yup.string().required('Location is required'),
});

export const trainInitialValue = {
  trainName: "",
  trainNumber: "",
  departureTime: "",
  departurePlace: "",
  arrivalPlace: "",
  arrivalTime: "",
}

export const trainValidationSchema = Yup.object().shape({
  trainName: Yup.string().required('Name is required'),
  trainNumber: Yup.string().required('Train number is required'),
  departureTime: Yup.string().required('Departure date is required'),
  departurePlace: Yup.mixed().required('Departure place is required'),
  arrivalPlace: Yup.mixed().required('Arrival place is required'),
  arrivalTime: Yup.string().required('Arrival date is required'),
});

export const trainCoachInitialValue = {
  // name:"",
  NoOFSeat: "",
  price: "",
  coachType: "",
}

export const trainCoachValidationSchema = Yup.object().shape({
  // name:Yup.string().required('Name is required'),
  NoOFSeat: Yup.string().required('Seats is required'),
  price: Yup.string().required('Price is required'),
  coachType: Yup.string().required('Coach type is required'),
});

export const trainStationInitialValue = {
  name: "",
  alias: "",
}

export const trainStationValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  alias: Yup.string().required('Alias is required'),
});

export const trainStopInitialValue = {
  plateFormNo: "",
  departureTime: "",
  arrivalTime: "",
  stationId: {},
}

export const trainStopValidationSchema = Yup.object().shape({
  plateFormNo: Yup.string().required('Plateform No is required'),
  departureTime: Yup.string().required('Departure time is required'),
  arrivalTime: Yup.string().required('Arrival time is required'),
  stationId: Yup.object().required('Please select station').test('select-station', 'Please select station', (value) => {
    if (value?.value) {
      return true;
    }
  }),

});




// add form validation

export const addFormIntialValues = {
  formLink: "",
  myDocumentId: ""
}

export const addFormValidationSchema = Yup.object().shape({
  formLink: Yup.string()
    .required('Form link is required'),
  myDocumentId: Yup.string()
    .required('Document is required'),
});

// entertainment validation 

export const entertainmentInitialValues = {
  title: "",
  subtitle: "",
  publishDate: "",
  content: '',
  contentSource: "",
  type: "inshorts",
  thumbnail: "",
  isPublish: false,
  isBanner: false,
  video: "",
  creatorName: "",
  videoTimeDuration: ""
}

export const entertainmentValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .max(150, 'Title must not exceed 150 characters'),
  subtitle: Yup.string().required('Subtitle is required')
    .max(150, 'Subtitle must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),
  content: Yup.string().required('Content is required'),
  creatorName: Yup.string().required('Creator name is required'),
  contentSource: Yup.string().required('Content source is required')
    .max(100, 'Content source must not exceed 100 characters'),
  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('Video is required')
    }
    return schema;
  },
  ),
});

// add doc validation

export const addDocIntialValue = {
  docName: '',
  imageLogo: '',
}

export const addDocValidationSchema = Yup.object().shape({
  docName: Yup.string().required("Doc name is required"),
  imageLogo: Yup.string().required("Image is requred")
})

// living validation 

export const livingInitialValues = {
  title: "",
  subtitle: "",
  publishDate: '',
  content: '',
  contentSource: "",
  type: "article",
  thumbnail: "",
  isPublish: false,
  isBanner: false,
  isPopular: false,
  creatorName: "",
  video: "",
  videoTimeDuration: ""
}

export const livingValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
    .max(150, 'Title must not exceed 150 characters'),
  subtitle: Yup.string().required('Subtitle is required').max(150, 'Subtitle must not exceed 150 characters'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),
  content: Yup.string().required('Content is required'),
  creatorName: Yup.string().required('Creator name is required'),
  contentSource: Yup.string().required('Content source is required').max(100, 'Content source must not exceed 100 characters'),
  type: Yup.string().required('Type is required'),
  thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string()
    }
    return schema;
  },
  ),
  video: Yup.string().when('type', ([type], schema) => {
    if (type === 'video') {
      return Yup.string().required('Video is required')
    }
    return schema;
  },
  ),
});

// study validations 
export const courseInitialValues = {
  courseTitle: "",
  publisher: "",
  isBanner: false,
  isPopular: false,
  isPublished: false,
  courseThumbnail: "",
  content: "",
  publishDate: "",
  completionTime: "",
  creditPoints: "",
}

export const courseValidationSchema = Yup.object().shape({
  courseTitle: Yup.string().required('Course title is required').max(150, 'Course title must not exceed 150 characters'),
  publisher: Yup.string().required('Creator Name is required').max(100, 'Creator Name must not exceed 100 characters'),
  courseThumbnail: Yup.string().required('Course thumbnail is required'),
  content: Yup.string().required('Description is required'),
  publishDate: Yup.string().required('Publish date is required').test('valid-date', 'Invalid date format. Use MM-DD-YYYY', (value) => {
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (!dateRegex.test(value)) {
      return false;
    }
    const [month, day, year] = value.split('-').map(Number);
    const dateObject = new Date(year, month - 1, day);
    return (
      dateObject.getDate() === day &&
      dateObject.getMonth() === month - 1 &&
      dateObject.getFullYear() === year
    );
  }),

  completionTime: Yup.number().required("Completion time is required").typeError("must be a number"),
  creditPoints: Yup.number().required("Credit points time is required").typeError("must be a number"),
});

export const addChapterIntialValue = {
  courseId: "",
  courseChapter: [{
    type: "video",
    chapterTitle: "",
    thumbnail: "",
    videos: "",
    videoTimeDuration: "",
    content: "",
  }]
}

export const addChapterValidationSchema = Yup.object().shape({
  courseId: Yup.string().required('Course is required'),
  courseChapter: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().oneOf(['article', 'video']).required('Chapter type is required'),
      chapterTitle: Yup.string().required('Chapter title is required').max(150, 'chapter title must not exceed 150 characters'),
      thumbnail: Yup.string().required('Thumbnail is required').when('type', ([type], schema) => {
        if (type === 'video') {
          return Yup.string()
        }
        return schema;
      },
      ),
      videos: Yup.string().when('type', ([type], schema) => {
        if (type === 'video') {
          return Yup.string().required('Video is required')
        }
        return schema;
      },
      ),
      content: Yup.string().required("Content is required")
    })
  ),
});

export const addQuizInitialValue = {
  courseId: "",
  quesAndAns: [{
    questions: "",
    correctAnswer: "",
    answer: [{
      name: ""
    }, {
      name: ""
    }],
  }]
}

export const addQuizValidationSchema = Yup.object().shape({
  courseId: Yup.string().required('Course is required'),
  quesAndAns: Yup.array().of(
    Yup.object().shape({
      questions: Yup.string().required('Question is required'),
      correctAnswer: Yup.string().required('Answer is required')
        .test("is-valid-answer", "Answer should be in options", (value, validationContext) => {
          const { parent, createError } = validationContext;
          let element = parent?.answer?.filter((ele, i, arr) => {
            if (ele?.name === value) return true;
            return false;
          })
          if (element?.length === 1) {
            return true;
          } else if (element?.length > 1) {
            return createError({ message: "Answer should be match with one option" })
          } else {
            return false;
          }
        })
      ,
      answer: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Option is required')
        })
      ),
    })
  ),
});


// shop

export const addShopInitialValue = {
  name: "",
  emailId: "",
  location: ""
}

export const addShopValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  location: Yup.string().required("Address is required")
})

export const addProductInitialValue = {

  products: [{
    name: "",
    category: "",
    subcategory: "",
    price: "",
    deletedPrice: "",
    discount: "",
    productImage: [],
    desc: "",
    size: [],
    color: [{ color: "", colorImage: "" }],
    remainingIs: "",
    status: "",
  }]
}

export const addProductValidationSchema = Yup.object().shape({

  products: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Product name is required'),
    category: Yup.mixed().required('Category is required'),
    subcategory: Yup.mixed().required('Subcategory is required'),
    price: Yup.string()
      .required('Price is required')
      .test('is-positive', 'Price must be a positive number', value => parseFloat(value) >= 0),
    deletedPrice: Yup.string()
      .required('Deleted price is required')
      .test('is-positive', 'Deleted price must be a positive number', value => parseFloat(value) >= 0),
    discount: Yup.string()
      .required('Discount is required')
      .test('is-valid-discount', 'Discount must be between 0 and 100', value => {
        const discountValue = parseFloat(value);
        return discountValue >= 0 && discountValue <= 100;
      }),
    productImage: Yup.array().of(Yup.string())
      .min(1, 'At least one product image is required'),

    desc: Yup.string().required('Description is required'),
    size: Yup.array().of(Yup.string())
      .min(1, 'At least one size is required'),
    color: Yup.array().of(Yup.object().shape({
      color: Yup.string().required('Color is required'),
      colorImage: Yup.string().required('Color image is required'),
    }))
      .min(1, 'At least one product image is required'),
    status: Yup.string().required('Status is required'),
    remainingIs: Yup.string()
      .required('Remaining is required')
      .test('is-positive', 'Remaining must be a positive number', value => parseFloat(value) >= 0),
  })).min(1, 'At least one product is required'),
});

export const updateProductInitialValue = {
  name: "",
  category: "",
  subcategory: "",
  price: "",
  deletedPrice: "",
  discount: "",
  productImage: [],
  desc: "",
  size: [],
  color: [{ color: "", colorImage: "" }],
  remainingIs: "",
}

export const updateProductValidationSchma = Yup.object().shape({
  name: Yup.string().required('Product name is required'),
  category: Yup.mixed().required('Category is required'),
  subcategory: Yup.mixed().required('Subcategory is required'),
  price: Yup.string()
    .required('Price is required')
    .test('is-positive', 'Price must be a positive number', value => parseFloat(value) >= 0),
  deletedPrice: Yup.string()
    .required('Deleted price is required')
    .test('is-positive', 'Deleted price must be a positive number', value => parseFloat(value) >= 0),
  discount: Yup.string()
    .required('Discount is required')
    .test('is-valid-discount', 'Discount must be between 0 and 100', value => {
      const discountValue = parseFloat(value);
      return discountValue >= 0 && discountValue <= 100;
    }),
  productImage: Yup.array().of(Yup.string())
    .min(1, 'At least one product image is required'),
  desc: Yup.string().required('Description is required'),
  size: Yup.array().of(Yup.string())
    .min(1, 'At least one size is required'),
  remainingIs: Yup.string()
    .required('Remaining is required')
    .test('is-positive', 'Remaining must be a positive number', value => parseFloat(value) >= 0),

  color: Yup.array().of(Yup.object().shape({
    color: Yup.string().required('Color is required'),
    colorImage: Yup.string().required('Color image is required'),
  }))
    .min(1, 'At least one product image is required'),
})





export const manageCarouselIntialValue = {
  id: ''
}

export const manageCarouselValidationSchema = Yup.object().shape({
  id: Yup.mixed().required('Id is required')
});

export const pageManagementIntialValue = {
  name: "About us",
  content: ""
}

export const pageMangagementValidationSchema = Yup.object().shape({
  name: Yup.mixed().required('name is required'),
  content: Yup.mixed().required('content is required')
});

export const systemAndreportInitialValue = {
  from: "",
  to: "",
  type: "users"
}

export const systemAndreportValidationSchema = Yup.object().shape({
  from: Yup.string().required('name is required'),
  to: Yup.string().required('To is required'),
  type: Yup.string().required('Type is required'),
})

export const addApplinksInitialValue = {
  name: "",
  image: "",
  androidUrl: "",
  iosUrl: "",
}

export const addApplinksValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.string().required('Image is required'),
  androidUrl: Yup.string().url("Invalid Url").required('Android url is required'),
  iosUrl: Yup.string().url("Invalid Url").required('IOS url is required'),
})

export const categoryInitialValue = {
  name: "",
  image: "",
  description: ""
}

export const categoryValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.string().required('Image is required'),
})

export const subCategoryInitialValue = {
  name: "",

}

export const subCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export const userDocumentsInitialValues = {
  userId: "",
  categoryId: "",
  name: "",
  nationality: "",
  dob: '',
  gender: "",
  dateOfIssue: '',
  dateOfExpiry: '',
  placeOfBirth: "",
  issuedPlace: "",
  nrcNumber: "",
  fatherName: "",
  bloodGroup: "",
  phoneNumber: "",
  address: "",
  drivingLicense: "",
  visaNumber: "",
  passportNumber: "",
  validUntil: '',
  typeOfVisa: "",
  householdMemberQty: '',
  companyName: "",
  companyID: "",
  companyAddress: "",
  registrationDate: '',
  typeOfBusiness: "",
  workPermitNo: "",
  typeOfWork: "",
  allowWorkUntil: '',
  aliasName: "",
  parentName: "",
  religion: "",
  language: "",
  permanentAddress: "",
  myanmarPhone: "",
  relationship: "",
  companyPhone: "",
  entryDateThailand: '',
  addressInThailand: "",
  thaiPhone: "",
  ciOldLicenseNumber: "",
}

export const passportIntialValue = {
  name: '',
  nationality: '',
  dob: '',
  gender: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  placeOfBirth: '',
  issuedPlace: '',
}

export const passportValidationSchma = Yup.object({
  name: Yup.string().required('Name is required'),
  nationality: Yup.string().required('Nationality is required'),
  dob: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  file: Yup.string().required('File is required'),
});

export const WorkpermitCiCardIntialValue = {
  workPermitNo: '',
  name: '',
  dob: '',
  companyAddress: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  typeOfWork: '',
  allowWorkUntil: '',
  file: '',
}

export const WorkpermitCiCardValidation = Yup.object({
  workPermitNo: Yup.string().required('WorkPermit is required'),
  name: Yup.string().required('Name is required'),
  dob: Yup.date().required('Date of Birth is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  typeOfWork: Yup.string().required('Type of Work is required'),
  allowWorkUntil: Yup.date().required('Allow Work Until is required'),
  file: Yup.string().required('File is required'),
});

export const WorkpermitCiApplicatonFormIntialValue = {
  name: '',
  aliasName: '',
  dob: '',
  nrcNumber: '',
  parentName: '',
  religion: '',
  language: '',
  permanentAddress: '',
  myanmarPhone: '',
  relationship: '',
  companyName: '',
  companyAddress: '',
  companyPhone: '',
  entryDateThailand: '',
  addressInThailand: '',
  thaiPhone: '',
  ciOldLicenseNumber: '',
  dateOfIssue: '',
  file: '',
  placeOfBirth: "",
  issuedPlace: '',
}

export const WorkpermitCiApplicatonFormValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  aliasName: Yup.string().required('Alias name is required'),
  dob: Yup.date().required('Date of Birth is required'),
  nrcNumber: Yup.string().required('Nrc is required'),
  parentName: Yup.string().required('Parent Name is required'),
  religion: Yup.string().required('Religion is required'),
  language: Yup.string().required('Language is required'),
  permanentAddress: Yup.string().required('Permanent Address is required'),
  myanmarPhone: Yup.string().required('Myanmar Phone is required'),
  relationship: Yup.string().required('Relationship is required'),
  companyName: Yup.string().required('Company Name is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  companyPhone: Yup.string().required('Company Phone is required'),
  entryDateThailand: Yup.date().required('Entry Date in Thailand is required'),
  addressInThailand: Yup.string().required('Address in Thailand is required'),
  thaiPhone: Yup.string().required('Thai Phone is required'),
  ciOldLicenseNumber: Yup.string().required('CI Old License Number is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  file: Yup.string().required('File is required'),
});

export const NationalIdFormIntialvalue = {
  nrcNumber: '',
  name: '',
  fatherName: '',
  dob: '',
  bloodGroup: '',
  gender: '',
  phoneNumber: '',
  address: '',
  file: '',
}

export const NationalIdFormValidation = Yup.object({
  nrcNumber: Yup.string().required('NRC Number is required'),
  name: Yup.string().required('Name is required'),
  fatherName: Yup.string().required("Father Name is required"),
  dob: Yup.date().required('Date of Birth is required'),
  bloodGroup: Yup.string().required("Blood Group is required"),
  gender: Yup.string().required('Gender is required'),
  phoneNumber: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  file: Yup.string().required("file is required"),
})

export const DrivingLicenseIntialvalue = {
  name: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  dob: '',
  drivingLicense: '',
  file: '',
}

export const DrivingLicenseValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  dob: Yup.date().required('Date of Birth is required'),
  drivingLicense: Yup.string().required('Driving License is required'),
  file: Yup.string().required('File is required'),
})

export const VisaIntialValue = {
  visaNumber: '',
  passportNumber: '',
  validUntil: '',
  typeOfVisa: '',
  file: '',
}


export const VisaValidation = Yup.object({
  visaNumber: Yup.string().required('Visa Number is required'),
  passportNumber: Yup.string().required('Passport Number is required'),
  validUntil: Yup.date().required('Valid Until is required'),
  typeOfVisa: Yup.string().required('Type of Visa is required'),
  file: Yup.string().required('File is required'),
})

export const HouseholdMemberInitialValue = {
  name: "",
  householdMemberQty: "",
  address: "",
  file: "",
}

export const HouseholdMemberValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  householdMemberQty: Yup.number().required('Household Member Quantity is required').typeError("must be a number"),
  address: Yup.string().required("Address is required"),
  file: Yup.string().required("File is required"),
})

export const otherInitialValue = {
  name: "",
  file: "",
}

export const otherValidation = Yup.object({
  name: Yup.string().required('Name is required'),
  file: Yup.string().required("File is required"),
})


export const EmployerInformationInitialValue = {
  companyName: '',
  companyID: '',
  registrationDate: '',
  dateOfIssue: '',
  dateOfExpiry: '',
  companyAddress: '',
  typeOfBusiness: '',
  file: '',
}


export const EmployerInformationValidation = Yup.object({
  companyName: Yup.string().required('Company Name is required'),
  companyID: Yup.string().required('Company ID is required'),
  registrationDate: Yup.date().required('Registration Date is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
  file: Yup.string().required('File is required'),
});

export const userDocumentsValidationSchema = Yup.object().shape({
  categoryId: Yup.mixed().required('Category is required'),
});

const userDocumentsValidationSchema1 = Yup.object().shape({
  categoryId: Yup.mixed().required('Category is required'),
  name: Yup.string().required('Name is required'),
  nationality: Yup.string().required('Nationality is required'),
  dob: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfIssue: Yup.date().required('Date of Issue is required'),
  dateOfExpiry: Yup.date().required('Date of Expiry is required'),
  placeOfBirth: Yup.string().required('Place of Birth is required'),
  issuedPlace: Yup.string().required('Issued Place is required'),
  workPermitNo: Yup.string().required('Work Permit is required'),
  companyAddress: Yup.string().required('Company Address is required'),
  typeOfWork: Yup.string().required('Type of Work is required'),
  allowWorkUntil: Yup.date().required('Allow Work Until is required'),
  aliasName: Yup.string().required('Alias Name is required'),
  nrcNumber: Yup.string().required('NRC Number is required'),
  parentName: Yup.string().required('Parent Name is required'),
  religion: Yup.string().required('Religion is required'),
  language: Yup.string().required('Language is required'),
  permanentAddress: Yup.string().required('Permanent Address is required'),
  myanmarPhone: Yup.string().required('Myanmar Phone is required'),
  relationship: Yup.string().required('Relationship is required'),
  companyName: Yup.string().required('Company Name is required'),
  companyPhone: Yup.string().required('Company Phone is required'),
  entryDateThailand: Yup.date().required('Entry Date in Thailand is required'),
  addressInThailand: Yup.string().required('Address in Thailand is required'),
  thaiPhone: Yup.string().required('Thai Phone is required'),
  ciOldLicenseNumber: Yup.string().required('CI Old License Number is required'),
  fatherName: Yup.string().required('Father Name is required'),
  bloodGroup: Yup.string().required('Blood Group is required'),
  phone: Yup.string().required('Phone is required'),
  address: Yup.string().required('Address is required'),
  drivingLicense: Yup.string().required('Driving License is required'),
  visaNumber: Yup.string().required('Visa Number is required'),
  passportNumber: Yup.string().required('Passport Number is required'),
  validUntil: Yup.date().required('Valid Until is required'),
  typeOfVisa: Yup.string().required('Type of Visa is required'),
  householdMemberQty: Yup.number().required('Household Member Quantity is required').typeError("must be a number"),
  companyID: Yup.string().required('Company ID is required'),
  registrationDate: Yup.date().required('Registration Date is required'),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
});


export const bannerInitialValue = {
  image: "",
  description: "",
}

export const bannerValidationSchema = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  description: Yup.string().required('Description is required'),

});

export const compensationRecommendation = {
  spousePassport: "",
  repersentativeLetter: "",
  marriedCerticated: "",
  marriedRecommendation: "",
}

export const compensationRecommendationValidation = Yup.object().shape({
  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  representativeLetter: Yup.string()
    .trim()
    .required('Representative Letter is required'),

  marriedCertificate: Yup.string()
    .trim()
    .required('Marriage Certificate is required'),

  marriedRecommendation: Yup.string()
    .trim()
    .required('Marriage Recommendation is required'),
});


export const bodyDispatchRecommendation = {
  passport: "",
  nationalRegistration: "",
  spousePassport: "",
  spouseNRC: "",
  deathCertificate: "",
  criminalRecord: "",
}

export const bodyDispatchRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  spouseNRC: Yup.string()
    .trim()
    .required('Spouse NRC is required'),

  deathCertificate: Yup.string()
    .trim()
    .required('Death Certificate is required'),

  criminalRecord: Yup.string()
    .trim()
    .required('Criminal Record is required'),
});

export const prisonRecommendation = {
  visitorPassport: "",
  name: "",
  address: "",
  passport: "",
  nationalRegistration: "",
  houseHoldlist: "",
  prisonerNumber: "",
}

export const prisonRecommendationValidation = Yup.object().shape({
  visitorPassport: Yup.string()
    .trim()
    .required('Visitor Passport is required'),

  name: Yup.string()
    .trim()
    .required('Name is required'),

  address: Yup.string()
    .trim()
    .required('Address is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  prisonerNumber: Yup.string()
    .trim()
    .required('Prisoner Number is required'),
});

export const sponsorRecommendation = {
  passport: "",
  religiousRecommendation: "",
  saranaIdentityCard: "",
}

export const sponsorRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  religiousRecommendation: Yup.string()
    .trim()
    .required('Religious Recommendation is required'),

  saranaIdentityCard: Yup.string()
    .trim()
    .required('Sarana Identity Card is required'),
});

export const visaExtensionRecommendation = {
  passport: "",
  universityRecommendation: "",
  hospitalRecommendation: "",
}

export const visaExtensionRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  hospitalRecommendation: Yup.string()
    .trim()
    .required('Hospital Recommendation is required'),
});

export const dependentRecommendation = {
  spousePassport: "",
  parentsPassport: "",
  taxpayer: "",
  voucher: "",
  marriedCerticated: "",
}

export const dependentRecommendationValidation = Yup.object().shape({
  spousePassport: Yup.string()
    .trim()
    .required('Spouse Passport is required'),

  parentsPassport: Yup.string()
    .trim()
    .required('Parents Passport is required'),

  taxpayer: Yup.string()
    .trim()
    .required('Taxpayer information is required'),

  voucher: Yup.string()
    .trim()
    .required('Voucher is required'),

  marriedCerticated: Yup.string()
    .trim()
    .required('Marriage Certificate is required'),
});

export const studentRecommendation = {
  passport: "",
  universityRecommendation: "",
}

export const studentRecommendationValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),
});

export const birthRecommendation = {
  parentsPassport: "",
  thaiBirthCertificate: "",
  myanmarBirthCertificate: "",
}

export const birthRecommendationValidation = Yup.object().shape({
  parentsPassport: Yup.string()
    .trim()
    .required('Parents Passport is required'),

  thaiBirthCertificate: Yup.string()
    .trim()
    .required('Thai Birth Certificate is required'),

  myanmarBirthCertificate: Yup.string()
    .trim()
    .required('Myanmar Birth Certificate is required'),
});

export const bankAcOpeningRecommendation = {
  passportPhoto: "",
  passport: "",
  ci: "",
}

export const bankAcOpeningRecommendationValidation = Yup.object().shape({
  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  ci: Yup.string()
    .trim()
    .required('CI is required'),
});

export const ciLostRecommendation = {
  lostRecommendation: "",
  passport: "",
  labourCard: "",
  passportPhoto: "",
}

export const ciLostRecommendationValidation = Yup.object().shape({
  lostRecommendation: Yup.string()
    .trim()
    .required('Lost Recommendation is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  labourCard: Yup.string()
    .trim()
    .required('Labour Card is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),
});

export const addressVerifyAndApprove = {
  passport: "",
  passportPhoto: "",
  address: "",
}

export const addressVerifyAndApproveValidation = Yup.object().shape({
  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),

  address: Yup.string()
    .trim()
    .required('Address is required'),
});

export const cerficateOfIdentityInitialValue = {
  applicationLetter: "",
  applicationForm: "",
  lostRecommendation: "",
  passport: "",
  nationalRegistration: "",
  returnTicket: "",
  passportPhoto: "",
}

export const cerficateOfIdentityValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  lostRecommendation: Yup.string()
    .trim()
    .required('Lost Recommendation is required'),

  passport: Yup.string()
    .trim()
    .required('Passport is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  returnTicket: Yup.string()
    .trim()
    .required('Return Ticket is required'),

  passportPhoto: Yup.string()
    .trim()
    .required('Passport Photo is required'),
});


export const religiousPassport = {
  applicationLetter: "",
  applicationForm: "",
  universityRecommendation: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  saranaIdentityCard: "",
}

export const religiousPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  saranaIdentityCard: Yup.string()
    .trim()
    .required('Sarana Identity Card is required'),
});

export const studentPassport = {
  applicationLetter: "",
  applicationForm: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  universityRecommendation: "",
  studentCard: "",
}

export const studentPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  universityRecommendation: Yup.string()
    .trim()
    .required('University Recommendation is required'),

  studentCard: Yup.string()
    .trim()
    .required('Student Card is required'),
});

export const dependentPassportIntialValue = {
  applicationLetter: "",
  applicationForm: "",
  marriedCerticate: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
  granterPassport: "",
}

export const dependentPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  marriedCertificate: Yup.string()
    .trim()
    .required('Married Certificate is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),

  granterPassport: Yup.string()
    .trim()
    .required('Granter Passport is required'),
});

export const jobPassportIntialValue = {
  applicationLetter: "",
  applicationForm: "",
  workPermit: "",
  companyRecommendation: "",
  nationalRegistration: "",
  houseHoldlist: "",
  currentPassport: "",
}

export const jobPassportValidation = Yup.object().shape({
  applicationLetter: Yup.string()
    .trim()
    .required('Application Letter is required'),

  applicationForm: Yup.string()
    .trim()
    .required('Application Form is required'),

  workPermit: Yup.string()
    .trim()
    .required('Work Permit is required'),

  companyRecommendation: Yup.string()
    .trim()
    .required('Company Recommendation is required'),

  nationalRegistration: Yup.string()
    .trim()
    .required('National Registration is required'),

  houseHoldlist: Yup.string()
    .trim()
    .required('Household List is required'),

  currentPassport: Yup.string()
    .trim()
    .required('Current Passport is required'),
});


export const applicationFeildRowData = {
  "Passport for Job (PJ)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "workPermit", type: "input", inputType: "file", placeholder: "workPermit", label: "Work permit" },
      { name: "companyRecommendation", type: "input", inputType: "file", placeholder: "companyRecommendation", label:"Company recommendation" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
    ],
    initialValue: jobPassportIntialValue,
    validation: jobPassportValidation
  },
  "Passport for Dependent (PT)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "marriedCerticate", type: "input", inputType: "file", placeholder: "marriedCerticate", label: "Married certificate"},
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "granterPassport", type: "input", inputType: "file", placeholder: "granterPassport", label: "Granter Passport" },
    ],
    initialValue: dependentPassportIntialValue,
    validation: dependentPassportValidation,
  },
  "Passport for Student (PE)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "studentCard", type: "input", inputType: "file", placeholder: "studentCard", label: "Student card" },
    ],
    initialValue: studentPassport,
    validation: studentPassportValidation
  },
  "Passport for Religious (PR)": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "currentPassport", type: "input", inputType: "file", placeholder: "currentPassport", label: "Current passport" },
      { name: "saranaIdentityCard", type: "input", inputType: "file", placeholder: "saranaIdentityCard", label: "Sarana identity card"},
    ],
    initialValue: religiousPassport,
    validation: religiousPassportValidation
  },
  "Certificate of Identity": {
    feilds: [
      { name: "applicationLetter", type: "input", inputType: "file", placeholder: "applicationLetter", label: "Application letter" },
      { name: "applicationForm", type: "input", inputType: "file", placeholder: "applicationForm", label: "Application form" },
      { name: "lostRecommendation", type: "input", inputType: "file", placeholder: "lostRecommendation", label: "Lost recommendation" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "returnTicket", type: "input", inputType: "file", placeholder: "returnTicket", label: "Return Ticket" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
    ],
    initialValue: cerficateOfIdentityInitialValue,
    validation: cerficateOfIdentityValidation
  },
  "Address verify and approval": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
      { name: "address", type: "input", inputType: "text", placeholder: "address", label: "Address" },

    ],
    initialValue: addressVerifyAndApprove,
    validation: addressVerifyAndApproveValidation
  },
  "The recommendation for CI lost": {
    feilds: [
      { name: "lostRecommendation", type: "input", inputType: "file", placeholder: "lostRecommendation", label: "Lost recommendation" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "labourCard", type: "input", inputType: "file", placeholder: "labourCard", label: "Labour card" },
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },

    ],
    initialValue: ciLostRecommendation,
    validation: ciLostRecommendationValidation
  },
  "The recommendation to open bank account": {
    feilds: [
      { name: "passportPhoto", type: "input", inputType: "file", placeholder: "passportPhoto", label: "Passport size photo" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "ci", type: "input", inputType: "file", placeholder: "ci", label: "Ci" },
    ],
    initialValue: bankAcOpeningRecommendation,
    validation: bankAcOpeningRecommendationValidation
  },
  "The recommendation for birth certification": {
    feilds: [
      { name: "parentsPassport", type: "input", inputType: "file", placeholder: "parentsPassport", label: "Parent passport" },
      { name: "thaiBirthCertificate", type: "input", inputType: "file", placeholder: "thaiBirthCertificate", label: "Thai birth certificate" },
      { name: "myanmarBirthCertificate", type: "input", inputType: "file", placeholder: "myanmarBirthCertificate", label: "Myanmar birth certificate"},
    ],
    initialValue: birthRecommendation,
    validation: birthRecommendationValidation
  },
  "The recommendation for dependent": {
    feilds: [
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "parentsPassport", type: "input", inputType: "file", placeholder: "parentsPassport", label: "Parent passport" },
      { name: "taxpayer", type: "input", inputType: "file", placeholder: "taxpayer", label: "Taxpayer" },
      { name: "voucher", type: "input", inputType: "file", placeholder: "voucher", label: "Voucher" },
      { name: "marriedCerticated", type: "input", inputType: "file", placeholder: "marriedCerticated", label: "Married certificate" },
    ],
    initialValue: dependentRecommendation,
    validation: dependentRecommendationValidation
  },
  "The recommendation for student": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },

    ],
    initialValue: studentRecommendation,
    validation: studentRecommendationValidation
  },
  "The recommendation for visa extention": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "universityRecommendation", type: "input", inputType: "file", placeholder: "universityRecommendation", label: "University recommendation" },
      { name: "hospitalRecommendation", type: "input", inputType: "file", placeholder: "hospitalRecommendation", label: "Hospital recommendation" },
    ],
    initialValue: visaExtensionRecommendation,
    validation: visaExtensionRecommendationValidation
  },

  "The recommendation for sponsor letter": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "religiousRecommendation", type: "input", inputType: "file", placeholder: "religiousRecommendation", label: "Religious recommendation" },
      { name: "saranaIdentityCard", type: "input", inputType: "file", placeholder: "saranaIdentityCard", label: "Sarana identity Card"},
    ],
    initialValue: sponsorRecommendation,
    validation: sponsorRecommendationValidation
  },

  "The recommendation for prison entry": {
    feilds: [
      { name: "visitorPassport", type: "input", inputType: "file", placeholder: "visitorPassport", label: "Visitor passport" },
      { name: "name", type: "input", inputType: "text", placeholder: "name", label: "Name" },
      { name: "address", type: "input", inputType: "text", placeholder: "address", label: "Address" },
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "houseHoldlist", type: "input", inputType: "file", placeholder: "houseHoldlist", label: "Household list" },
      { name: "prisonerNumber", type: "input", inputType: "text", placeholder: "prisonerNumber", label: "Prisoner number" },
    ],
    initialValue: prisonRecommendation,
    validation: prisonRecommendationValidation
  },
  "The recommendation for body dispatch": {
    feilds: [
      { name: "passport", type: "input", inputType: "file", placeholder: "passport", label: "Passport" },
      { name: "nationalRegistration", type: "input", inputType: "file", placeholder: "nationalRegistration", label: "National registration" },
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "spouseNRC", type: "input", inputType: "file", placeholder: "spouseNRC", label: "Spouse NRC" },
      { name: "deathCertificate", type: "input", inputType: "file", placeholder: "deathCertificate", label: "Death certificate" },
      { name: "criminalRecord", type: "input", inputType: "file", placeholder: "criminalRecord", label: "Criminal record" },
    ],
    initialValue: bodyDispatchRecommendation,
    validation: bodyDispatchRecommendationValidation
  },
  "The recommendation for compensation": {
    feilds: [
      { name: "spousePassport", type: "input", inputType: "file", placeholder: "spousePassport", label: "Spouse passport" },
      { name: "repersentativeLetter", type: "input", inputType: "file", placeholder: "repersentativeLetter", label: "Repersentative letter" },
      { name: "marriedCerticated", type: "input", inputType: "file", placeholder: "marriedCerticated", label: "Married certificate" },
      { name: "marriedRecommendation", type: "input", inputType: "file", placeholder: "marriedRecommendation", label: "Married recommendation" },
    ],
    initialValue: compensationRecommendation,
    validation: compensationRecommendationValidation
  },
}

