import React from 'react'
import Spinwheel from '../../../common/Spinwheel'

function AddCinemaModal({ formik, modalId, addLoader }) {


  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12" style={{ zIndex: "1" }}>
              <form action="" className='p-4' >
                <h2 className='text-center'>
                  Movie theater
                </h2>
                <div className='d-flex flex-column gap-2'>
                  <div className="position-relative">
                    <label htmlFor='name'>Name</label>
                    <input className="form-control border border-1" type="text" id="name" placeholder="Enter cinema name" name="name" value={formik.values?.name} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.name ? (<div className='req-error'>{formik.errors?.name}</div>) : null}
                  </div>
                  <div className="position-relative">
                    <label htmlFor='location'>Location</label>
                    <input className="form-control border border-1" type="text" id="location" placeholder="Enter cinema location" name="location" value={formik.values?.location} onChange={formik.handleChange} onBlur={() => formik.handleBlur} />
                    {formik.touched?.location ? (<div className='req-error'>{formik.errors?.location}</div>) : null}
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center pt-4">
                  <button type="button" className="btn-custom w-auto" onClick={(e) => { formik?.handleSubmit() }}  >{addLoader ? <Spinwheel sizeClass="spin-small" /> : "Save"}</button>
                  <button type="button" className="btn-custom w-auto" data-bs-dismiss='modal' >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddCinemaModal