import React, { useEffect, useState } from "react";

import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import { ImageUrl } from "../../../utils/BaseUrl";
import { getAllUsers, removeBlockOrUnBlockUserApi } from "../../../utils/ApiEndpointFunctions";
import { debounce } from "lodash";
import Spinwheel from "../../common/Spinwheel";
import Pagination from "../../common/Pagination";
import Header from "../../common/Header";
import RemoveModal from "../../common/RemoveModal";
import toast from 'react-hot-toast';

export default function Users() {
    const { adminDetail, } = useAdminContext()
    let [selectedPage, setSelectPage] = useState(0);
    const [allUser, setAllUser] = useState(null);
    const [page, setPage] = useState(0);
    const [allUserSpin, setAlluserSpin] = useState(true)
    const limit = 10;
    const [searchText, setSearchText] = useState("");
    const removeModalId = "removeModalId"
    const blockModalId = "blockModalId"
    const [selectedId, setSelectedId] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const navigate = useNavigate()
    let [sort, setSort] = useState("createdAtDesc")


    const getAllUser = async () => {
        setAlluserSpin(false)
        const res = await getAllUsers(limit, selectedPage + 1, searchText, sort);
        if (res.status === 200) {
            setAllUser(res.data?.data)
            const totaldata = res.data?.totalData
            setPage(Math.ceil(totaldata / limit))
            setAlluserSpin(true)
        }
    }
    const handlePageClick = (event) => {
        setSelectPage(event.selected)
        selectedPage = event.selected;
        getAllUser(event.selected)
    };

    const onChange = async (e) => {
        let search = e.target.value
        setAlluserSpin(false)
        setSearchText(search);
        const res = await getAllUsers(limit, 1, search, sort);
        if (res.status === 200) {
            setAllUser(res.data?.data)
            const totaldata = res.data?.totalData
            setPage(Math.ceil(totaldata / limit))
            setSelectPage(0)
            setAlluserSpin(true)
        }
    }
    const debouncedOnChange = debounce(onChange, 500);

    const removeHandler = async () => {
        try {

            if (selectedStatus === "remove") {
                document.getElementById(removeModalId).click();
            } else {
                document.getElementById(blockModalId).click();
            }

            const res = await removeBlockOrUnBlockUserApi({ _id: selectedId, status: selectedStatus });
            if (res.status === 200) {
                getAllUser();
                toast.success(res.data?.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllUser();
    }, [sort]);
    return (
        <>
            {/*<Sidebar />*/}
            <div className="main-content vh-100 vh_md-auto overflow-y-a">
                <Header title="Users" />
                <div className="container-fluid">
                    <div className="row">
                        <nav className="mb-5">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">
                                        <i className="fe fe-home"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Users
                                </li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-end mb-4 flex-wrap">
                           

                            <div className="me-3" >  <select class="form-select sort-select sort-dropdown  form-select-sm" style={{ padding:"0.5rem 2rem 0.5rem 0.75rem"}} value={sort} onChange={(e) => { setSort(e.target.value)}}>
                                <option value="nameAsc">A to Z</option>
                                <option value="nameDesc">Z to A</option>
                                <option value="createdAtDesc">Newest</option>
                                <option value="createdAtAsc">Oldest</option>
                            </select></div>

                            <div style={{ display: "-webkit-inline-box" }}>
                                <div className="position-relative">
                                    <input
                                        className="form-search me-3"
                                        type="search"
                                        name=""
                                        onChange={debouncedOnChange}
                                        placeholder="Search"
                                    />
                                    <img
                                        className="users-search"
                                        src="/images/Icon feather-search.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="card">
                        <div className="" style={{ overflowX: "auto" }}>
                            <table className="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                S.No
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="list-sort text-muted"
                                                // data-sort="tables-first"
                                                // style={{ cursor: "pointer" }}
                                            >
                                                User Name
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Gender
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Correspondence Address
                                            </span>
                                        </th>
                                        <th>
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Email ID
                                            </span>
                                        </th>
                                       
                                        <th >
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Phone number
                                            </span>
                                        </th>
                                        <th >
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Job type
                                            </span>
                                        </th>
                                        <th colSpan={2}>
                                            <span
                                                className="list-sort text-muted"

                                            >
                                                Status
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                {
                                    allUserSpin ? <tbody className="list fs-base">
                                        {
                                            allUser?.length > 0 ?
                                                allUser?.map?.((ele, index) => {
                                                   
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(selectedPage * limit) + index + 1}</td>
                                                            <td>
                                                                {
                                                                    ele?.name ? <>
                                                                        <div className="avatar avatar-xs align-middle me-2">
                                                                            <img
                                                                                className="avatar-img rounded-circle"
                                                                                src={ele?.userDetails?.profile && ele?.userDetails?.profile !== "null" ? ImageUrl + ele?.userDetails?.profile : "/images/person-circle.svg"}
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <span
                                                                            className="item-name text-reset"
                                                                        >
                                                                            {ele?.name}
                                                                        </span>
                                                                    </> : "-"
                                                                }
                                                            </td>
                                                            <td>
                                                                <span className="item-title">
                                                                    {ele?.gender ? ele?.gender : "-"}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="item-title">
                                                                    {`${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}`.length ?
                                                                        `${ele?.userDetails?.correspondence_Address ? ele?.userDetails?.correspondence_Address + ", " : ""}${ele?.userDetails?.correspondence_country ? ele?.userDetails?.correspondence_country + ", " : ""}${ele?.userDetails?.correspondence_zipcode ? ele?.userDetails?.correspondence_zipcode + ", " : ""}` : "-"}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="item-email text-reset"
                                                                >
                                                                    {ele?.email}
                                                                </span>
                                                            </td>
                                                            {/* <td>
                                                                <span
                                                                    className="item-phone text-reset"
                                                                >
                                                                    {`${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}`.length ?
                                                                        `${ele?.userDetails?.work_Address ? ele?.userDetails?.work_Address + ", " : ""}${ele?.userDetails?.work_country ? ele?.userDetails?.work_country + ", " : ""}${ele?.userDetails?.work_zipcode ? ele?.userDetails?.work_zipcode + ", " : ""}` : "-"}
                                                                </span>
                                                            </td> */}
                                                            <td>
                                                                <span
                                                                    className="item-phone text-reset"
                                                                >
                                                                    {ele?.phoneNumber ? ele?.phoneNumber : '-'}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="item-phone text-reset"
                                                                >
                                                                    {ele?.userDetails?.jobTitle ? ele?.userDetails?.jobTitle : '-'}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className="item-phone text-reset"
                                                                >
                                                                    <div className={`badge ${ele?.isActive ? "badge bg-success-soft" : "bg-dark-soft"} `}>
                                                                        {ele?.isActive ? "Active" : "Blocked"}
                                                                    </div>
                                                                </span>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown">
                                                                    <Link
                                                                        className="dropdown-ellipses dropdown-toggle"
                                                                        role="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <i className="fe fe-more-vertical" />
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <div style={{ cursor: "pointer" }} className="dropdown-item" onClick={() => { navigate(`/users/${ele._id}`) }}>
                                                                            View
                                                                        </div>
                                                                        <Link to={"/users/edit/" + ele._id} className="dropdown-item" >
                                                                            Edit
                                                                        </Link>

                                                                        <div data-bs-toggle="modal" data-bs-target={`#${removeModalId}`} style={{ cursor: "pointer" }} className="dropdown-item" onClick={() => { setSelectedId(ele._id); setSelectedStatus("remove") }}>
                                                                            Remove
                                                                        </div>

                                                                        {ele?.isActive === true && <div data-bs-toggle="modal" data-bs-target={`#${blockModalId}`} style={{ cursor: "pointer" }} className="dropdown-item" onClick={() => { setSelectedId(ele._id); setSelectedStatus("block") }}>
                                                                            Block
                                                                        </div>}

                                                                        {ele?.isActive === false && <div data-bs-toggle="modal" data-bs-target={`#${blockModalId}`} style={{ cursor: "pointer" }} className="dropdown-item" onClick={() => { setSelectedId(ele._id); setSelectedStatus("unblock") }}>
                                                                            Unblock
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr align="center">
                                                    <td colSpan="12">
                                                        <h1>Data Not Found</h1>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody> : <tbody>
                                        <tr align="center">
                                            <td colSpan="12">
                                                <Spinwheel />
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {allUser?.length > 0 ? <Pagination
                            clickHandler={handlePageClick}
                            page={page}
                            selectedPage={selectedPage}
                        /> : null}
                    </div>
                </div>
            </div>
            <div className="modal fade" id={blockModalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pe-0 ps-0 block-user">
                            <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
                            <h1 className="mb-0">Are you sure you want to<br /> {selectedStatus === "unblock" ? "unblock": "block"} this User ?</h1>
                        </div>
                        <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
                            <button type="button" className="btn-custom w-auto" onClick={removeHandler} >YES</button>
                            <button type="button" className="btn-custom w-auto mt-2" data-bs-dismiss='modal' >
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <RemoveModal modalId={removeModalId} removeHandler={removeHandler} message={<>Are you sure you want to<br /> remove this?</>} />
        </>
    );
}
