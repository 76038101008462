import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { getAllMovieApi, removeMovieApi } from '../../../../utils/ApiEndpointFunctions';
import Header from '../../../common/Header';
import RemoveModal from '../../../common/RemoveModal';

import Pagination from '../../../common/Pagination';
import Spinwheel from '../../../common/Spinwheel';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

function Movies() {
  const navigate = useNavigate()
  const [tableSpin, setTableSpin] = useState(true);
  const [movies, setMovies] = useState([]);
  let [selectedPage, setSelectPage] = useState(0);
  const [selectedMovie, setSelectedMovie] = useState(null)
  const [page, setPage] = useState(0);
  const removeMovieModalId = "removeMovieModalId"
  const limit = 10;

  const getAllMovieList = async (selectedPage, search) => {
    try {
      setTableSpin(false)
      const res = await getAllMovieApi(search || "", selectedPage + 1, limit)
      if (res.status === 200) {
        setMovies(res.data?.data?.data)
        const totaldata = res.data?.data?.totalData

        setPage(Math.ceil(totaldata / limit));
      }
      setTableSpin(true)
    } catch (error) {
      setTableSpin(true)
    }
  }



  const handlePageClick = (event) => {
    setSelectPage(event.selected);
    getAllMovieList(event.selected)
  };

  const handleRemoveMovie = async () => {
    try {
      const res = await removeMovieApi(selectedMovie);
      if (res?.status === 200) {
        toast.success(res.data?.message)
        getAllMovieList(0)
        document.getElementById(removeMovieModalId).click()
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const onChange = async (e) => {
    let search = e.target.value;
    getAllMovieList(0, search)
  };

  const debouncedOnChange = debounce(onChange, 500);



  useEffect(() => {
    getAllMovieList(selectedPage)
  }, [])




  return (
    <>
      {/*<Sidebar />*/}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        <Header title="Movie" />
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <Link to={"/ticket/movie/tickets"} className="breadcrumb-item active" aria-current="page">
                  Movie ticket
                </Link>
                <li className="breadcrumb-item active" aria-current="page">
                  Movies
                </li>
              </ol>
            </nav>
            <div className="mb-4" style={{ textAlign: "end" }}>
              <div className='d-flex justify-content-end gap-2' style={{ display: "-webkit-inline-box" }}>
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                <button onClick={() => navigate(`/ticket/movies/movie/carousel`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Carousel
                  </button>
                  <button onClick={() => navigate(`/ticket/movie`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Add
                  </button>
                  <button onClick={() => navigate(`/ticket/movie/cinema`)} className="btn-custom w-auto" style={{ cursor: "pointer" }}>
                    Cinema
                  </button>
                  <div className="position-relative">
                    <input
                      className="form-search me-3"
                      type="search"
                      name=""
                      onChange={debouncedOnChange}
                      placeholder="Search"
                    />
                    <img
                      className="users-search"
                      src="/images/Icon feather-search.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="card p-3 table-responsive" >
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Title
                      </span>
                    </th>
                    <th></th>


                  </tr>
                </thead>
                {
                  tableSpin ?
                    <tbody className="list fs-base">
                      {movies?.length > 0 ? movies?.map((ele, index) => {
                        return (<tr key={index} >
                          <td>{index + 1}</td>
                          <td className='position-relative'>
                            <div className="avatar avatar-xs align-middle me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={ele?.coverImg ? ImageUrl + ele?.coverImg : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                alt=""
                              />
                            </div>
                            {ele?.name?.length > 30 ? <span>
                              {ele?.name?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.name}</div>
                              </div>
                            </span> :
                              <span>{ele?.name || "-"}</span>}

                          </td>
                          <td className='position-relative'>
                            {ele?.title?.length > 30 ? <span>
                              {ele?.title?.substr(0, 30)}...
                              <div className="tooltip1 item-name text-reset" ></div>
                              <div className='wrapper'>
                                <div className="tooltip1-text">{ele?.title}</div>
                              </div>
                            </span> :
                              <span>{ele?.title || "-"}</span>}

                          </td>

                          <td className="text-end">
                            {ele?.status?.toLowerCase() != "transferred" &&
                              <div className="dropdown">
                                <span
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <Link to={`/ticket/movie/${ele?._id}`} className="dropdown-item" style={{ cursor: "pointer" }}>
                                    view / edit
                                  </Link>
                                  <Link to={`/ticket/movie/show/${ele?._id}`} className="dropdown-item" style={{ cursor: "pointer" }}>
                                    view / add show
                                  </Link>
                                  <div data-bs-toggle="modal" data-bs-target={`#${removeMovieModalId}`} className=' dropdown-item' style={{ cursor: "pointer" }} onClick={() => { setSelectedMovie(ele?._id) }}>
                                    Remove
                                  </div>
                                </div>
                              </div>}
                          </td>
                        </tr>
                        )
                      }) : <tr align="center">
                        <td colSpan="9">
                          <h1>
                            Data Not Found
                          </h1>
                        </td>
                      </tr>}
                    </tbody> : (
                      <tbody>
                        <tr align="center">
                          <td colSpan="9">
                            <Spinwheel />
                          </td>
                        </tr>
                      </tbody>
                    )
                }
              </table>
            </div>
            <div className="d-flex justify-content-center">
              {movies?.length > 0 ? <Pagination
                clickHandler={handlePageClick}
                page={page}
                selectedPage={selectedPage}
              />
                : null}
            </div>
          </div>
          <RemoveModal removeHandler={handleRemoveMovie} modalId={removeMovieModalId} message={"Are you sure you want to remove?"} />
        </div>
      </div>
    </>
  )
}

export default Movies