import React, { useEffect, useState } from 'react'
import Spinwheel from '../../../common/Spinwheel'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import AsyncSelect from 'react-select/async';
import { getBusTicketByIdApi, getMovieTicketByIdApi } from '../../../../utils/ApiEndpointFunctions';
import moment from 'moment';
import { ImageUrl } from '../../../../utils/BaseUrl';
import { Link } from 'react-router-dom';

function ViewBusTicket({ id, modalId, }) {
   const [data, setData] = useState({})
   const [loading, setLoading] = useState(true)


   const getBusTicket = async () => {
      try {
         const res = await getBusTicketByIdApi(id)
         if (res.status === 200 && res.data?.data) {
            setData(res.data?.data);
         }
         setLoading(false)
      } catch (error) {
         // setLoading(true)
      }
   }

   useEffect(() => {
      if (id) {
         getBusTicket()
      }
   }, [id])
console.log(data);

   return (
      <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header border-0">
                  <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">
                  <div className="col-lg-12" style={{ zIndex: "1" }}>
                     <div action="" className='p-4' >
                        <h2 className='text-center'>
                           Bus Ticket
                        </h2>
                        {loading ?
                           <Spinwheel /> :
                           <div className="row">
                                   <div className="mb-3">
                                    <h4 className='fw-bold'>User name</h4>
                                    <p className='text-capitalize mb-0'>{data?.userId?.name || "-"}</p>
                                 </div>
                           <h5 className='fw-bold text-center'>Bus route</h5>
                              <div className="row row-cols-lg-3 row-cols-md-2">
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Departure</h4>
                                    <div className='position-relative'>
                                       <p className='mb-0'>{data?.busRouteId?.departurePlace}</p>
                                    </div>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Arrival</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.arrivalPlace}</p>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Departure Time</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.departure ? moment(data?.busRouteId?.departure).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Arrival Time</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.arrivalTime ? moment(data?.busRouteId?.arrivalTime).format("DD/MM/YYYY h:mm:ss a") : "-"}</p>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Bus type</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.busType ? data?.busRouteId?.busType : "-"}</p>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Seat type</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.seatType ? data?.busRouteId?.seatType : "-"}</p>
                                 </div>
                                 </div>
                                 <hr/>
                                 <h5 className='fw-bold text-center'>Bus operator</h5>
                                 <div className="row row-cols-lg-3 row-cols-md-2">
                                 <div className='position-relative'>
                                       <div className="avatar avatar-xs align-middle me-2">
                                          <img
                                             className="avatar-img rounded-circle"
                                             src={data?.busRouteId?.busOperatorId?.image ? ImageUrl + data?.busRouteId?.busOperatorId?.image : process.env.PUBLIC_URL + "/images/person-circle.svg"}
                                             alt=""
                                          />
                                       </div>
                                       <p className='mb-0 capitalize'>{data?.busRouteId?.busOperatorId?.name ? data?.busRouteId?.busOperatorId?.name : "-"}</p>
                                    </div>
                                    <div className="mb-3">
                                    <h4 className='fw-bold'>Address</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.busOperatorId?.address ? data?.busRouteId?.busOperatorId?.address : "-"}</p>
                                 </div>
                             
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Phone no.</h4>
                                    <p className='text-capitalize mb-0'>{data?.busRouteId?.busOperatorId?.phone || "-"}</p>
                                 </div>
                                 
                              </div>
                              <hr/>
                                 <h5 className='fw-bold text-center'>Payment</h5>
                                 <div className="row row-cols-lg-3 row-cols-md-2">
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Amount</h4>
                                    <p className='mb-0'>{data?.payment?.amount}</p>
                                 </div>
                                 <div className="mb-3">
                                    <h4 className='fw-bold'>Status</h4>
                                    <p className='mb-0'>{data?.payment?.status == "success" ? "Yes" : "No"}</p>
                                 </div>
                                    </div>
                                    
                              <hr />
                              <h5 className='fw-bold text-center'>Ticket</h5>
                              <div className="row row-cols-lg-3 row-cols-md-2">
                                      <div>
                                    <h4 className='fw-bold'>Pickup point</h4>
                                    <p className='mb-0'>{data?.busRouteId?.pickPoint?.[0]?.place}</p>
                                 </div>
                                    <div>
                                    <h4 className='fw-bold'>Drop point</h4>
                                    <p className='mb-0'>{data?.busRouteId?.dropPoint?.[0]?.place}</p>
                                 </div>
                                 <div>
                                    <h4 className='fw-bold'>Tickets</h4>
                                    <p className='mb-0'>{data?.passengers?.length}</p>
                                 </div>
                                 <div>
                                    <h4 className='fw-bold'>Status</h4>
                                    <p className='mb-0'>{data?.status}</p>
                                 </div>
                                 <div>
                                    {data?.ticketFile && <div>
                                       <h4 className='fw-bold'>Attachment</h4>
                                       {console.log(data.ticketFile)}
                                       <Link to={ImageUrl + encodeURIComponent(data?.ticketFile)} target='_blank'>
                                          View
                                       </Link>
                                    </div>}
                                 </div>
                              </div>
                               <hr />
                              <h5 className='fw-bold text-center'>Passengers</h5>
                              <div className="row row-cols-md-2">
                                  <div>
                                    <h4 className='fw-bold'>Name</h4>
                                 </div>
                                  <div>
                                    <h4 className='fw-bold'>Age</h4>
                                 </div>
                                 {data?.passengers?.map(passenger=><>
                                     <div>
                                  <p className='mb-0'>{passenger?.name}</p>
                                 </div>
                                  <div>
                                  <p className='mb-0'>{passenger?.age}</p>
                                 </div>
                                 </>)}
                               </div>
                           </div>}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ViewBusTicket