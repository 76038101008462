import React from 'react'

function Attachment({ formik, docSpin, handlefile,feildName }) {

  const handleLable = (text, num) => {
    if (text?.length > num) return text?.slice(0, num) + "..."
    return text
  }

  return (
    <div className="position-relative mb-2">
      <label className="uploadFile form-control border mt-3">
        <span className="filename">
          {feildName ? formik?.values?.[feildName] ? handleLable(formik?.values?.[feildName]?.split("/")?.[1],30) : "Attachment" : formik?.values?.file ? handleLable(formik?.values?.file?.split("/")?.[1], 60) :"Attachment"}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip input-icon" viewBox="0 0 16 16">
          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
        </svg>
        <input type="file" className="inputfile form-control" name="imageLogo" disabled={docSpin} accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain" onChange={(e) => { handlefile(e, formik) }} />
      </label>
      {formik?.touched?.file && formik?.errors?.file ? (<div className='req-error'>{formik?.errors?.file}</div>) : null}
    </div>
  )
}

export default Attachment